export interface MenuItem {
  id: number;
  variant: "headerActive" | "headerInactive";
  text: string;
}

export interface PillItem {
  id: number;
  label: string;
  option: string;
}

export const loginData: MenuItem[] = [
  { id: 1, variant: "headerActive", text: "1. ENQUIRY DETAILS" },
  { id: 2, variant: "headerInactive", text: "2. LENDER MATCHES" },
  { id: 3, variant: "headerInactive", text: "3. BESPOKE TERMS" },
  { id: 4, variant: "headerInactive", text: "4. APPLICATIONS" },
];

export const pillData: PillItem[] = [
  { id: 1, label: "CREATE BORROWER", option: "borrower" },
  { id: 2, label: "CREATE BROKER", option: "broker" },
];

import useAxios from "axios-hooks";

const useRecoverAccount = (id: string | undefined) => {
  const postURL = `/api/v1/users/${id}/recover-account`;

  const [{ loading: recovering, error: recoverError }, recoverAccount] =
    useAxios(
      {
        url: postURL,
        method: "POST",
      },
      { manual: true },
    );

  const handleRecoverAccount = async () => {
    try {
      await recoverAccount();
      return { success: true };
    } catch (error) {
      console.error("Account recovery request failed:", error);
      return { success: false, error };
    }
  };

  return { handleRecoverAccount, recovering, recoverError };
};

export default useRecoverAccount;

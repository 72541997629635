import { useState } from "react";

const useValidateInput = () => {
  const [errors, setErrors] = useState({
    general: { error: false, helperText: "" },
    email: { error: false, helperText: "" },
    phone: { error: false, helperText: "" },
    password: { error: false, helperText: "" },
    confirmPassword: { error: false, helperText: "" },
    firstName: { error: false, helperText: "" },
    lastName: { error: false, helperText: "" },
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(?:\+44|0)(7\d{9}|[1-9]\d{1,4}[ ]?\d{4,8})$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/;
  const nameRegex = /^[A-Za-z\s]+$/;

  const emailCheck = (value: string) => emailRegex.test(value);

  const phoneCheck = (value: string) =>
    phoneRegex.test(value) &&
    (value.startsWith("+44") ? value.length === 13 : value.length === 11);

  const passwordCheck = (value: string) => passwordRegex.test(value);

  const confirmPasswordCheck = (password: string, confirmPassword: string) =>
    password === confirmPassword;

  const nameCheck = (value: string) =>
    value.trim() !== "" && nameRegex.test(value);

  const validateField = (
    type: "email" | "phone" | "firstName" | "lastName",
    value: string,
  ) => {
    if (type === "email") {
      const isValid = emailCheck(value);
      setErrors((prev) => ({
        ...prev,
        email: {
          error: !isValid,
          helperText: isValid ? "" : "Please enter a valid email.",
        },
      }));
      return isValid;
    } else if (type === "phone") {
      const isValid = phoneCheck(value);
      setErrors((prev) => ({
        ...prev,
        phone: {
          error: !isValid,
          helperText: isValid ? "" : "Please enter a valid phone number.",
        },
      }));
      return isValid;
    } else if (type === "firstName" || type === "lastName") {
      const isValid = nameCheck(value);
      setErrors((prev) => ({
        ...prev,
        [type]: {
          error: !isValid,
          helperText: isValid
            ? ""
            : `${type === "firstName" ? "First" : "Last"} name cannot be empty and must contain only letters.`,
        },
      }));
      return isValid;
    }
  };

  const validateEmailOrPhone = (value: string) => {
    const isEmailValid = emailCheck(value);
    const isPhoneValid = phoneCheck(value);

    if (isEmailValid || isPhoneValid) {
      setErrors((prev) => ({
        ...prev,
        general: { error: false, helperText: "" },
      }));
      return true;
    } else {
      setErrors((prev) => ({
        ...prev,
        general: {
          error: true,
          helperText: "Please enter a valid email or phone number.",
        },
      }));
      return false;
    }
  };

  const validatePassword = (
    type: "password" | "confirmPassword" | "changedPassword",
    value: string,
    originalPassword?: string,
  ) => {
    if (type === "password" || type === "changedPassword") {
      const isValid = passwordCheck(value);
      setErrors((prev) => ({
        ...prev,
        password: {
          error: !isValid,
          helperText: isValid
            ? ""
            : "Password must be at least 12 characters long and include uppercase, lowercase, numbers, and symbols.",
        },
      }));
      return isValid;
    } else if (type === "confirmPassword" && originalPassword) {
      const isValid = confirmPasswordCheck(originalPassword, value);
      setErrors((prev) => ({
        ...prev,
        confirmPassword: {
          error: !isValid,
          helperText: isValid ? "" : "Passwords do not match.",
        },
      }));
      return isValid;
    }
  };

  return {
    errors,
    validateField,
    validateEmailOrPhone,
    validatePassword,
  };
};

export default useValidateInput;

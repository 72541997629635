import React from "react";
import { Box, BoxProps, useTheme } from "@mui/material";

type StyledBoxProps = BoxProps;

const DropShadowBox: React.FC<StyledBoxProps> = ({ children, ...props }) => {
  const theme = useTheme();
  const { dropShadowBox } = theme.custom;

  return (
    <Box sx={dropShadowBox} {...props}>
      {children}
    </Box>
  );
};

export default DropShadowBox;

import { createTheme } from "@mui/material/styles";
import { typography } from "./typography";
import { components } from "./components";
import { shape } from "./shape";
import { custom } from "./custom";
import palette from "./palette";

export const provideTheme = createTheme({
  palette,
  typography,
  components,
  shape,
  custom,
});

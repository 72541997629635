import { Components, createTheme, Theme } from "@mui/material/styles";
import palette from "./palette";

const provideTheme: Theme = createTheme({ palette });
export const components: Components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        position: "relative",
        backgroundColor: "white",
        boxShadow: "none",
        borderBottom: `1px solid ${provideTheme.palette.midGray}`,
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        padding: "0 0 0 20px",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 27,
        fontFamily: "Roboto",
        fontSize: "1rem",
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&.icon-button": {
          color: "black",
          padding: "12px",
          borderRadius: "12px",
          width: "56px",
          height: "32px",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        width: "100%",
        height: "51px",
        fontSize: "1rem",
        boxShadow: "none",
        borderRadius: 27,
        "&.MuiButton-contained": {
          backgroundColor: "black",
          color: "white",
          "&:disabled": {
            opacity: 0.25,
          },
        },
        "&.MuiButton-outlined": {
          backgroundColor: "white",
          color: "black",
          borderColor: provideTheme.palette.midGray,
          padding: "12px 40px",
          height: "auto",
          "&:disabled": {
            opacity: 0.5,
          },
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: 0,
        "&.Mui-checked": {
          color: "black",
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 0,
        color: "black",
        "&.Mui-checked": {
          color: "black",
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontFamily: "Roboto",
      },
      secondary: {
        fontFamily: "Roboto",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      slotProps: {
        inputLabel: {
          shrink: true,
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        backgroundColor: provideTheme.palette.gray.light,
        color: provideTheme.palette.text.disabled,
        border: "none",
        borderRadius: "10px",
        height: "30px",
        fontSize: "0.7rem",
        width: "60px",
        "&.Mui-selected": {
          backgroundColor: "black",
          color: "white",
        },
        "&.Mui-selected:hover": {
          backgroundColor: "black",
          color: "white",
        },
        "&:hover": {
          backgroundColor: provideTheme.palette.gray.medium,
          color: "white",
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: "black",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: provideTheme.palette.secondary.dark,
        color: "black",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        ml: "20px",
      },
    },
  },
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavigationState {
  drawerOpen: boolean;
}

const initialState: NavigationState = {
  drawerOpen: false,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.drawerOpen = action.payload;
    },
  },
});

export const { setDrawerOpen } = navigationSlice.actions;
export const selectDrawerOpen = (state: { navigation: NavigationState }) =>
  state.navigation.drawerOpen;
export default navigationSlice.reducer;

export interface SettingsPillItem {
  id: number;
  label: string;
  option: string;
}

export const settingsPillData: SettingsPillItem[] = [
  { id: 1, label: "MAIN DETAILS", option: "main" },
  { id: 2, label: "TEAMS & BRANCHES", option: "teams" },
  { id: 3, label: "NOTIFICATION SETTINGS", option: "notifications" },
];

import React, { useEffect } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import LoginWave from "../../components/Icons/LoginWave/LoginWave";
import RootUnauthScreen from "../RootScreen/RootUnauthScreen";
import DropShadowBox from "../../components/DropShadowBox/DropShadowBox";
import ProvideButton from "../../components/Buttons/ProvideButton";
import DisclaimerText from "../../components/DisclamerText/DisclamerText";
import { AnimatedBounceContainer } from "../../styled/AnimatedBounceContainer.styled";
import CheckmarkCircle from "../../components/Icons/CheckmarkCircle/CheckmarkCircle";
import RedCrossIcon from "../../components/Icons/RedCrossIcon/RedCrossIcon";
import useVerifyEmail from "../../hooks/useVerifyEmail";

const VerifyEmailScreen: React.FC = () => {
  const { id, hash } = useParams<{ id: string; hash: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const expires = searchParams.get("expires");
  const signature = searchParams.get("signature");

  const { handleVerifyEmail, verifyingEmail, verifyError, verifyData } =
    useVerifyEmail();

  const verify = () => {
    if (id && hash && expires && signature) {
      handleVerifyEmail(id, hash, expires, signature);
    }
  };

  useEffect(() => {
    verify();
  }, []);

  const renderContent = () => {
    if (verifyingEmail) {
      return <CircularProgress color="primary" />;
    }

    if (verifyData) {
      return (
        <>
          <AnimatedBounceContainer>
            <CheckmarkCircle />
          </AnimatedBounceContainer>
          <Typography textAlign="center">Email Verified</Typography>
          <ProvideButton
            variant="contained"
            onClick={() => navigate("/dashboard")}
            sx={{ width: 200 }}
          >
            Dashboard
          </ProvideButton>
        </>
      );
    }

    if (verifyError) {
      return (
        <>
          <AnimatedBounceContainer>
            <RedCrossIcon />
          </AnimatedBounceContainer>
          <Typography color="error" textAlign="center">
            {verifyError.response?.data.message || "Verification failed"}
          </Typography>
          <ProvideButton
            variant="contained"
            onClick={verify}
            sx={{ width: 200 }}
          >
            Try Again
          </ProvideButton>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <LoginWave />
      <RootUnauthScreen>
        <Grid container justifyContent="center">
          <Stack spacing={2} sx={{ zIndex: 1 }}>
            <Typography component="h1" variant="h1">
              Verify Email
            </Typography>
            <DropShadowBox>
              <Stack
                spacing={3}
                sx={{
                  zIndex: 1,
                  minHeight: 200,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {renderContent()}
              </Stack>
            </DropShadowBox>
            <DisclaimerText />
          </Stack>
        </Grid>
      </RootUnauthScreen>
    </>
  );
};

export default VerifyEmailScreen;

import useAxios from "axios-hooks";
import { EditUserPayload } from "../components/UserSettings/UserSettingsMain";
import { useState } from "react";

const useEditUser = (id: string | undefined) => {
  const postURL = `/api/v1/users/${id}`;
  const [editSuccess, setEditSuccess] = useState<boolean>(false);

  const [{ loading: editing, error: editError, data: editData }, register] =
    useAxios(
      {
        url: postURL,
        method: "PUT",
      },
      { manual: true },
    );

  const handleEditUser = async (payload: EditUserPayload) => {
    const { firstName, lastName, dob, phone, newPassword, currentPassword } =
      payload;

    try {
      await register({
        data: {
          first_name: firstName,
          last_name: lastName,
          date_of_birth: dob,
          phone_number: phone,
          ...(newPassword && {
            password: newPassword,
          }),
          ...(currentPassword && {
            current_password: currentPassword,
          }),
        },
      });

      setEditSuccess(true);
    } catch (error) {
      console.error("Edit user request failed:", error);
      setEditSuccess(false);
      return { error };
    }
  };

  return { handleEditUser, editing, editError, editData, editSuccess };
};

export default useEditUser;

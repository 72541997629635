import React, { PropsWithChildren } from "react";
import axios from "axios";
import { render } from "@testing-library/react";
import { BrowserRouter as Router } from "react-router-dom";
import type { RenderOptions } from "@testing-library/react";

import { Provider } from "react-redux";
import { setupStore, store } from "../store/store";
import type { AppStore, RootState } from "../store/store";

import { ThemeProvider } from "@mui/material/styles";
import { provideTheme } from "../themes/theme";

interface ExtendedRenderOptions extends Omit<RenderOptions, "queries"> {
  preloadedState?: Partial<RootState>;
  store?: AppStore;
}

export const renderWithProviders = (
  ui: React.ReactElement,
  {
    preloadedState = {},
    store = setupStore(preloadedState),
    ...renderOptions
  }: ExtendedRenderOptions = {},
) => {
  function Wrapper({ children }: PropsWithChildren<object>): JSX.Element {
    return <Provider store={store}>{children}</Provider>;
  }
  return { store, ...render(ui, { wrapper: Wrapper, ...renderOptions }) };
};

export const renderWithThemeAndRouter = (ui: React.ReactElement) => {
  return render(
    <Provider store={store}>
      <ThemeProvider theme={provideTheme}>
        <Router
          future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true,
          }}
        >
          {ui}
        </Router>
      </ThemeProvider>
    </Provider>,
  );
};

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift() || null;
  }
  return null;
};

export const getFrontEndUrl = async (): Promise<string> => {
  if (localStorage.getItem("PF-CONF") !== null) {
    return JSON.parse(localStorage.getItem("PF-CONF") as string).frontend_url;
  }
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/api/v1/whoami/" + location.hostname,
  );
  if (response.status === 200) {
    const data = response.data.data;

    localStorage.setItem(
      "PF-CONF",
      JSON.stringify({
        frontend_url: data.request_uri,
        name: data.company_name,
        primary_color: data.theme.primary_color ?? null,
        secondary_color: data.theme.secondary_color ?? null,
        tertiary_color: data.theme.tertiary_color ?? null,
        logo: data.theme.logo ?? null,
        t_name: data.tenant_name,
      }),
    );
    return response.data.request_uri;
  }

  return process.env.REACT_APP_FRONTEND_URL as string;
};

export const getCSRFTokenIfNeeded = async (): Promise<void> => {
  const xsrfToken = getCookie("XSRF-TOKEN");
  if (!xsrfToken) {
    console.debug("XSRF token not found, fetching CSRF cookie...");
    try {
      await axios.get("/sanctum/csrf-cookie");
      console.debug("CSRF cookie fetched successfully.");
    } catch (error) {
      console.error("Error fetching CSRF cookie:", error);
    }
  } else {
    console.debug("XSRF token present:");
  }
};

import React from "react";

interface AppleIconProps {
  width?: number;
  height?: number;
}

const AppleIcon: React.FC<AppleIconProps> = (props) => {
  const { width = 27, height = 32 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.065 30.652C21.3396 32.3246 19.4557 32.0605 17.6422 31.2682C15.7231 30.4583 13.9624 30.4231 11.9377 31.2682C9.40231 32.3598 8.06421 32.0429 6.55004 30.652C-2.04199 21.7959 -0.774309 8.30922 8.97975 7.81623C11.3566 7.93948 13.0117 9.11912 14.4026 9.22476C16.4802 8.8022 18.4697 7.58734 20.6881 7.7458C23.3467 7.95708 25.3539 9.01348 26.6744 10.915C21.1811 14.2074 22.484 21.4437 27.5195 23.4685C26.5159 26.1095 25.213 28.7329 23.0474 30.6696L23.065 30.652ZM14.2265 7.71059C13.9624 3.78432 17.1492 0.544702 20.8114 0.227783C21.322 4.77029 16.6914 8.15076 14.2265 7.71059Z"
        fill="black"
      />
    </svg>
  );
};

export default AppleIcon;

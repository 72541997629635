import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { provideTheme } from "./themes/theme";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import DashboardScreen from "./screens/DashboardScreen/DashboardScreen";
import SignUpScreen from "./screens/SignUpScreen/SignUpScreen";
import LoginSignupToolbar from "./components/LoginSignupToolbar/LoginSignupToolbar";
import SideNavigation from "./components/SideNavigation/SideNavigation";
import AuthWrapper from "./components/AuthWrapper/AuthWrapper";
import UserSettingsScreen from "./screens/UserSettingsScreen/UserSettingsScreen";
import VerifyEmailScreen from "./screens/VerifyEmailScreen/VerifyEmailScreen";

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={provideTheme}>
        <Router
          future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true,
          }}
        >
          <Routes>
            {/* Public routes */}
            <Route
              path="/"
              element={
                <AuthWrapper isProtected={false}>
                  <LoginSignupToolbar />
                </AuthWrapper>
              }
            >
              <Route index element={<LoginScreen />} />
              <Route path="/sign-up" element={<SignUpScreen />} />
              <Route
                path="/verify-email/:id/:hash"
                element={<VerifyEmailScreen />}
              />
            </Route>

            {/* Protected routes */}
            <Route
              element={
                <AuthWrapper isProtected={true}>
                  <SideNavigation />
                </AuthWrapper>
              }
            >
              <Route
                path="/dashboard"
                element={<DashboardScreen infoText="dashboard" />}
              />
              <Route path="/settings" element={<UserSettingsScreen />} />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;

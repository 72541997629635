import React, { useEffect, useState } from "react";
import {
  FormControl,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  Radio,
  RadioGroup,
  Divider,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import ProvideButton from "../Buttons/ProvideButton";
import useValidateInput from "../../hooks/useValidateInput";
import AppleIcon from "../Icons/AppleIcon/AppleIcon";
import GoogleIcon from "../Icons/GoogleIcon/GoogleIcon";
import FacebookIcon from "../Icons/FacebookIcon/FacebookIcon";
import useRegister from "../../hooks/useRegister";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useRedux";
import { login } from "../../store/slices/authSlice";

const SignUpBorrower: React.FC = () => {
  const { errors, validateField, validatePassword } = useValidateInput();
  const { handleRegister, registering, registerError } = useRegister();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [areInputsValidated, setAreInputsValidated] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    const result = await handleRegister(
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.password,
      formData.confirmPassword,
    );

    if (result.success) {
      dispatch(login());
      navigate("/dashboard");
    }
  };

  const handleChange =
    (field: keyof typeof formData) =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      if (field === "password" || field === "confirmPassword") {
        validatePassword(field, value, formData.password);
      } else {
        validateField(field, value);
      }
      setFormData((prev) => ({ ...prev, [field]: value }));
    };

  useEffect(() => {
    const { email, password, confirmPassword, firstName, lastName } = formData;

    if (confirmPassword.trim()) {
      validatePassword("confirmPassword", confirmPassword, password);
    }

    const isFieldValid = (field: string, error: boolean) =>
      !error && field.trim() !== "";

    const emailValid = isFieldValid(email, errors.email?.error);
    const passwordsValid =
      isFieldValid(password, errors.password?.error) &&
      isFieldValid(confirmPassword, errors.confirmPassword?.error);
    const namesValid =
      isFieldValid(firstName, errors.firstName?.error) &&
      isFieldValid(lastName, errors.lastName?.error);

    setAreInputsValidated(emailValid && passwordsValid && namesValid);
  }, [errors, formData]);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Stack
            spacing={3}
            justifyContent="space-between"
            direction={{ xs: "column", sm: "row" }}
            sx={{ flex: 1 }}
          >
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="first-name"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange("firstName")}
                error={errors.firstName?.error}
              />
              {errors.firstName?.error && (
                <FormHelperText error>
                  {errors.firstName.helperText}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="last-name"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange("lastName")}
                error={errors.lastName?.error}
              />
              {errors.lastName?.error && (
                <FormHelperText error>
                  {errors.lastName.helperText}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>

          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange("email")}
              error={errors.email?.error}
            />
            {errors.email?.error && (
              <FormHelperText error>{errors.email.helperText}</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <OutlinedInput id="phone" placeholder="Phone" />
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="password"
              autoComplete="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={formData.password}
              onChange={handleChange("password")}
              error={errors.password?.error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password?.error && (
              <FormHelperText error>
                {errors.password.helperText}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="confirm-password"
              autoComplete="confirm-password"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange("confirmPassword")}
              error={errors.confirmPassword?.error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.confirmPassword?.error && (
              <FormHelperText error>
                {errors.confirmPassword.helperText}
              </FormHelperText>
            )}
          </FormControl>
          <Stack spacing={3} sx={{ flex: 1 }}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid size={{ xs: 12, sm: 7 }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      I’m keen to hear about future offers
                    </Typography>
                  }
                />
              </Grid>

              <Grid size={{ xs: 12, sm: 5 }}>
                <RadioGroup row>
                  <Grid container spacing={2}>
                    <FormControlLabel
                      value="sms"
                      control={<Radio />}
                      label={<Typography>SMS Verify</Typography>}
                    />
                    <FormControlLabel
                      value="email"
                      control={<Radio />}
                      label={<Typography>Email Verify</Typography>}
                    />
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>

            <ProvideButton
              submitbtn="true"
              disabled={
                !!(
                  errors.email?.error ||
                  errors.phone?.error ||
                  !areInputsValidated ||
                  registering
                )
              }
              variant="contained"
            >
              {registering ? "Creating Account..." : "CREATE ACCOUNT"}
            </ProvideButton>

            {registerError && (
              <Typography color="error">
                {registerError.response?.data.message}
              </Typography>
            )}

            <Divider orientation="horizontal" sx={{ flex: 1 }} />

            <Grid container spacing={2}>
              <Typography>Or register via</Typography>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="left"
              >
                <AppleIcon />
                <GoogleIcon />
                <FacebookIcon />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </form>
    </>
  );
};

export default SignUpBorrower;

import useAxios from "axios-hooks";

const useFetchTeam = () => {
  const [{ data: teams, loading, error }] = useAxios({
    url: "/api/v1/teams",
    method: "GET",
  });

  return { teams, loading, error };
};

export default useFetchTeam;

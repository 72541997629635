import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import ProvideButton from "../Buttons/ProvideButton";
import PlainTextButton from "../Buttons/PlainTextButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import useEditUser from "../../hooks/useEditUser";
import ModalContainer from "../Modal/ModalContainer";
import ConfirmPasswordDialogue from "../Modal/ConfirmPasswordDialogue";
import useRecoverAccount from "../../hooks/useRecoverAccount";
import useValidateInput from "../../hooks/useValidateInput";

export interface EditUserPayload {
  firstName?: string;
  lastName?: string;
  dob?: string | null;
  phone?: string | null;
  newPassword?: string;
  currentPassword?: string;
}

const UserSettingsMain: React.FC = () => {
  const userSettings = useAppSelector(
    (state: RootState) => state.settings.userData,
  );

  const defaultFormData = {
    firstName: userSettings?.first_name || "",
    lastName: userSettings?.last_name || "",
    email: userSettings?.email || "",
    dob: userSettings?.date_of_birth || null,
    phone: userSettings?.phone_number || "",
    changedPassword: "",
  };

  const { handleEditUser, editing, editError } = useEditUser(userSettings?.id);
  const { handleRecoverAccount, recovering, recoverError } = useRecoverAccount(
    userSettings?.id,
  );
  const { errors, validateField, validatePassword } = useValidateInput();

  const [changedFields, setChangedFields] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [formData, setFormData] = useState(defaultFormData);

  const theme = useTheme();
  const { saveChangesBox } = theme.custom;

  useEffect(() => {
    const hasError = Object.values(errors).some(({ error }) => error);

    const hasChanges =
      !hasError &&
      [
        { current: formData.firstName, original: userSettings?.first_name },
        { current: formData.lastName, original: userSettings?.last_name },
        { current: formData.phone, original: userSettings?.phone_number },
        { current: formData.dob, original: userSettings?.date_of_birth },
        { current: formData.changedPassword, original: "" },
      ].some(
        ({ current, original }) => current?.trim() && current !== original,
      );

    setChangedFields(hasChanges);
  }, [formData, errors, userSettings]);

  const onCancelChanges = () => {
    setFormData(defaultFormData);
    setChangedFields(false);
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        dob: dayjs(newDate).format("YYYY-MM-DDTHH:mm:ss"),
      }));
    }
  };

  const preparePayload = (
    newPassword?: string,
    currentPassword?: string,
  ): EditUserPayload => ({
    ...formData,
    ...(newPassword && { newPassword }),
    ...(currentPassword && { currentPassword }),
  });

  const submitRequest = async (payload: EditUserPayload) => {
    await handleEditUser(payload);
    setIsModalOpen(false);
    setChangedFields(false);
  };

  const onPost = () => {
    if (!formData.changedPassword) {
      submitRequest(preparePayload());
    } else {
      setIsModalOpen(true);
    }
  };

  const onModalAccept = (newPassword: string, currentPassword: string) => {
    submitRequest(preparePayload(newPassword, currentPassword));
  };

  const handleResetPassword = async () => {
    const request = await handleRecoverAccount();
    const success = request.success;
    setEmailSent(success);
  };

  const handleFormChange =
    (field: "firstName" | "lastName" | "phone" | "changedPassword") =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      if (field === "changedPassword") {
        validatePassword(field, value);
      } else if (
        field === "firstName" ||
        field === "lastName" ||
        field === "phone"
      ) {
        validateField(field, value);
      }
      setFormData((prev) => ({ ...prev, [field]: value }));
    };

  return (
    <>
      <Grid container direction="column" spacing={3} maxWidth={456}>
        <Typography variant="h4" component="h4">
          About Me
        </Typography>
        <Grid container alignItems="flex-start" spacing={2}>
          <Avatar sx={{ width: 77, height: 77 }}>AM</Avatar>
          <Grid container direction="column">
            <Grid>
              <Grid container direction="row" spacing={1} alignItems="center">
                <ProvideButton
                  sx={{
                    width: 220,
                    height: 30,
                  }}
                  variant="contained"
                >
                  <Typography variant="body2">UPLOAD PHOTO</Typography>
                </ProvideButton>
                <PlainTextButton>REMOVE PHOTO</PlainTextButton>
              </Grid>
            </Grid>

            <Grid>
              <Typography variant="body2">
                Supported JPG & PNG up to 2mb
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="email"
            autoComplete="email"
            label="Email Address"
            value={formData.email}
            disabled
            variant="outlined"
            placeholder="john.doe@gmail.com"
            fullWidth
          />
        </FormControl>

        <Grid container spacing={3}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="first-name"
                autoComplete="first-name"
                label="First Name"
                placeholder="John"
                variant="outlined"
                value={formData.firstName}
                onChange={handleFormChange("firstName")}
                error={errors.firstName?.error}
                fullWidth
              />
              {errors.firstName?.error && (
                <FormHelperText error>
                  {errors.firstName.helperText}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="last-name"
                autoComplete="family-name"
                label="Last Name"
                placeholder="Doe"
                variant="outlined"
                value={formData.lastName}
                onChange={handleFormChange("lastName")}
                error={errors.lastName?.error}
                fullWidth
              />
              {errors.lastName?.error && (
                <FormHelperText error>
                  {errors.lastName.helperText}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Birth"
                value={formData.dob ? dayjs(formData.dob) : null}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    sx: { width: "100%" },
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="phone"
                autoComplete="phone"
                label="Phone Number"
                value={formData.phone}
                placeholder="+44 759 333 2124"
                variant="outlined"
                onChange={handleFormChange("phone")}
                error={errors.phone?.error}
                fullWidth
              />
              {errors.phone?.error && (
                <FormHelperText error>{errors.phone.helperText}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <FormControl sx={{ width: 220 }} variant="outlined">
          <TextField
            id="title"
            select
            label="Title"
            defaultValue="Mr"
            slotProps={{
              select: {
                native: true,
              },
            }}
          >
            <option key="Mr" value="Mr">
              Mr
            </option>
            <option key="Mrs" value="Mrs">
              Mrs
            </option>
            <option key="Ms" value="Ms">
              Ms
            </option>
          </TextField>
        </FormControl>

        <Typography variant="h4" component="h4">
          Security
        </Typography>
        {recoverError && (
          <Typography color="error">
            {recoverError.response?.data.message}
          </Typography>
        )}
        {emailSent && <Typography>Please check your email</Typography>}
        <FormControl fullWidth variant="outlined">
          <TextField
            id="password"
            type="password"
            label="Change your password"
            placeholder="Enter new password"
            value={formData.changedPassword}
            onChange={handleFormChange("changedPassword")}
            error={errors.password?.error}
            variant="outlined"
            fullWidth
            sx={{
              width: 220,
            }}
          />
          {errors.password?.error && (
            <FormHelperText
              sx={{
                width: 220,
              }}
              error
            >
              {errors.password.helperText}
            </FormHelperText>
          )}
          {editError && (
            <Typography color="error">
              {editError.response?.data.message}
            </Typography>
          )}
        </FormControl>
        <Stack spacing={1} mb={4}>
          <ProvideButton
            variant="contained"
            disabled={recovering ? true : false}
            onClick={handleResetPassword}
            sx={{
              maxWidth: 220,
              height: 30,
            }}
          >
            {recovering ? (
              <Typography>Resetting password...</Typography>
            ) : (
              <Typography>RESET PASSWORD</Typography>
            )}
          </ProvideButton>
          <ProvideButton
            variant="contained"
            sx={{
              maxWidth: 220,
              height: 30,
            }}
          >
            <Typography>CHOOSE 2FA METHOD</Typography>
          </ProvideButton>
        </Stack>
        {changedFields ? (
          <Box sx={saveChangesBox}>
            <ProvideButton
              onClick={onPost}
              variant="contained"
              disabled={editing}
              sx={{
                width: 159,
                height: 42,
              }}
            >
              {editing ? (
                <Typography variant="body3">Saving...</Typography>
              ) : (
                <Typography variant="body3">SAVE CHANGES</Typography>
              )}
            </ProvideButton>
            <ProvideButton
              onClick={onCancelChanges}
              disabled={editing}
              sx={{
                width: 104,
                height: 42,
              }}
            >
              <Typography variant="body3">CANCEL</Typography>
            </ProvideButton>
          </Box>
        ) : null}
        <ModalContainer isOpen={isModalOpen}>
          <ConfirmPasswordDialogue
            title={"Please confirm your password"}
            onConfirm={onModalAccept}
            onClose={() => setIsModalOpen(false)}
            enteredPassword={formData.changedPassword}
          />
        </ModalContainer>
      </Grid>
    </>
  );
};

export default UserSettingsMain;

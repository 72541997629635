import { keyframes } from "@mui/system";
import { styled } from "@mui/material/styles";

export const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const subtleElastic = keyframes`
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
`;

export const AnimatedBounceContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  animation: `${fadeIn} 0.5s ease, ${subtleElastic} 1s ease`,
});

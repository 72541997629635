import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";

export interface UserData {
  id: string;
  email: string;
  email_verified_at: string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  tenant_id: string | null;
  company_id: string | null;
  first_name: string;
  last_name: string;
  date_of_birth: string | null;
  phone_number: string | null;
  country_of_residence: string | null;
  nationality: string | null;
  citizenship: string | null;
  job_title: string | null;
  preferences: string | null;
  roles: string[];
  permissions: string[];
  addresses: string[];
}

interface SettingsState {
  settingsOption: string;
  userData: UserData | null;
}

const initialState: SettingsState = {
  settingsOption: "main",
  userData: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettingsOption: (state, action: PayloadAction<string>) => {
      state.settingsOption = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
  },
});

export const checkUrlForSettingsOption = (): AppThunk => (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search);
  const option = searchParams.get("option");

  if (option === "main" || option === "teams" || option === "notifications") {
    dispatch(setSettingsOption(option));
  }
};

export const { setSettingsOption, setUserData } = settingsSlice.actions;
export default settingsSlice.reducer;

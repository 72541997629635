import useAxios from "axios-hooks";
import { setUserData } from "../store/slices/settingsSlice";
import { useAppDispatch } from "./useRedux";

const useLogin = () => {
  const dispatch = useAppDispatch();

  const [{ loading: loggingIn, error: loginError, data: loginData }, login] =
    useAxios(
      {
        url: "/login",
        method: "POST",
      },
      { manual: true },
    );

  const handleLogin = async (emailOrPhone: string, password: string) => {
    try {
      const response = await login({ data: { email: emailOrPhone, password } });
      dispatch(setUserData(response.data.data));
      return { success: true };
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return { handleLogin, loggingIn, loginError, loginData };
};

export default useLogin;

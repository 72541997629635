import React, { useRef, useState } from "react";
import { Typography, TextField, useTheme, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DropShadowBox from "../DropShadowBox/DropShadowBox";
import PlainTextButton from "../Buttons/PlainTextButton";
import { AxiosError } from "axios";

export interface VerifyCodeDialogueProps {
  title: string;
  description: string;
  statusMessage?: AxiosError<unknown, unknown> | null;
  otpMessage?: AxiosError<unknown, unknown> | null;
  onConfirm: (code: string) => void;
  onClose: () => void;
  numberOfBoxes: number;
}

const VerifyCodeDialogue: React.FC<VerifyCodeDialogueProps> = (props) => {
  const {
    title,
    description,
    onConfirm,
    onClose,
    numberOfBoxes,
    statusMessage,
    otpMessage,
  } = props;

  const [code, setCode] = useState<string[]>(Array(numberOfBoxes).fill(""));
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const theme = useTheme();

  const { inputField } = theme.custom.verifyCode;

  const updateCode = (newCode: string[], index: number) => {
    setCode(newCode);

    const isComplete = !newCode.includes("");

    if (isComplete) {
      onConfirm(newCode.join(""));
    } else if (index < numberOfBoxes - 1 && newCode[index]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      /*
        This ensures that only a single digit or no digit is allowed in the input.
        
      */
      if (/^\d?$/.test(value)) {
        const updatedCode = [...code];
        updatedCode[index] = value;
        updateCode(updatedCode, index);
      }
    };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    /*
      regex returns true if the string digit contains at least
      one numeric character (0-9), and false otherwise.
    
    */
    const pasteData = event.clipboardData
      .getData("Text")
      .slice(0, numberOfBoxes)
      .split("")
      .map((digit) => (/\d/.test(digit) ? digit : ""));

    const completePasteData = [
      ...pasteData,
      ...Array(numberOfBoxes - pasteData.length).fill(""),
    ];

    setCode(completePasteData);

    completePasteData.forEach((digit, idx) => {
      if (inputRefs.current[idx]) {
        inputRefs.current[idx]!.value = digit;
      }
    });

    updateCode(completePasteData, completePasteData.length - 1);
    event.preventDefault();
  };

  const handleKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Backspace" && !code[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    };

  return (
    <DropShadowBox>
      <Stack spacing={2}>
        <Grid container direction="column">
          <Typography component="h4" variant="h4">
            {title}
          </Typography>
          <Typography component="p" variant="body1">
            {description}
          </Typography>
        </Grid>

        <Grid container direction="column" spacing={1}>
          <Typography component="p" variant="body2">
            Enter Code
          </Typography>
          <Grid container spacing={1}>
            {code.map((digit, index) => (
              <TextField
                key={index}
                value={digit}
                onChange={handleChange(index)}
                onKeyDown={handleKeyDown(index)}
                inputRef={(el) => (inputRefs.current[index] = el)}
                slotProps={{
                  htmlInput: {
                    sx: { textAlign: "center" },
                    onPaste: handlePaste,
                  },
                }}
                sx={inputField}
              />
            ))}
          </Grid>
          {(statusMessage || otpMessage) && (
            <Typography color="error">
              {(statusMessage?.response?.data as { message: string })
                ?.message ||
                (otpMessage?.response?.data as { message: string })?.message}
            </Typography>
          )}
          <PlainTextButton onClick={onClose}>CLOSE</PlainTextButton>
        </Grid>
      </Stack>
    </DropShadowBox>
  );
};

export default VerifyCodeDialogue;

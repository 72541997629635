import { useTheme } from "@mui/material";
import React from "react";

interface RedCrossIconProps {
  width?: number;
  height?: number;
}

const RedCrossIcon: React.FC<RedCrossIconProps> = (props) => {
  const theme = useTheme();
  const { displayRed } = theme.palette;
  const { width = 71, height = 71 } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="35.5"
        cy="35.5"
        r="33.5"
        fill="white"
        stroke={displayRed}
        strokeWidth="4"
      />
      <line
        x1="22"
        y1="22"
        x2="49"
        y2="49"
        stroke={displayRed}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="49"
        y1="22"
        x2="22"
        y2="49"
        stroke={displayRed}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RedCrossIcon;

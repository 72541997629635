import React from "react";
import { Box } from "@mui/material";

interface RootScreenProps {
  children: React.ReactNode;
}

const RootUnauthScreen: React.FC<RootScreenProps> = ({ children }) => {
  return (
    <Box py={4} px={2}>
      {children}
    </Box>
  );
};

export default RootUnauthScreen;

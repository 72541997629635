import { useTheme } from "@mui/material";
import React from "react";

interface CheckmarkCircleProps {
  width?: number;
  height?: number;
}

const CheckmarkCircle: React.FC<CheckmarkCircleProps> = (props) => {
  const theme = useTheme();
  const { displayGreen } = theme.palette;
  const { width = 71, height = 71 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="35.5"
        cy="35.5121"
        r="33.4879"
        fill="white"
        stroke={displayGreen}
        strokeWidth="4"
      />
      <path
        d="M28.2178 44.6413L20.4727 36.8962C19.6097 36.0332 18.2377 36.0332 17.3747 36.8962C16.5117 37.7592 16.5117 39.1312 17.3747 39.9942L26.6467 49.2662C27.5097 50.1293 28.9038 50.1293 29.7668 49.2662L53.2234 25.8318C54.0864 24.9688 54.0864 23.5968 53.2234 22.7338C52.3604 21.8707 50.9884 21.8707 50.1254 22.7338L28.2178 44.6413Z"
        fill={displayGreen}
      />
    </svg>
  );
};

export default CheckmarkCircle;

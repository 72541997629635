import React from "react";
import AppleIcon from "../../Icons/AppleIcon/AppleIcon";
import FacebookIcon from "../../Icons/FacebookIcon/FacebookIcon";
import GoogleIcon from "../../Icons/GoogleIcon/GoogleIcon";

export const socialButtons = [
  {
    icon: <FacebookIcon />,
    label: "CONTINUE WITH FACEBOOK",
    onClick: () => console.debug("Login Facebook button"),
  },
  {
    icon: <GoogleIcon />,
    label: "CONTINUE WITH GOOGLE",
    onClick: () => console.debug("Login Google button"),
  },
  {
    icon: <AppleIcon />,
    label: "CONTINUE WITH APPLE",
    onClick: () => console.debug("Login Apple button"),
  },
];

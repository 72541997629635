import React, { useState } from "react";
import { Box, Typography, IconButton, Collapse } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Grid from "@mui/material/Grid2";

const DropdownInfoBox = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      width={{ xs: "100%", sm: "540px" }}
      borderRadius="27px"
      bgcolor="primary.light"
      boxSizing="border-box"
      p="20px"
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid>
              <InfoIcon sx={{ color: "blue" }} />
            </Grid>
            <Grid>
              <Typography variant="h2">
                Is the Provide service Free for me?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <IconButton
            sx={{ p: 0 }}
            onClick={handleToggle}
            aria-label="toggle dropdown"
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Grid>
      </Grid>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Typography variant="body2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Typography>
      </Collapse>
    </Box>
  );
};

export default DropdownInfoBox;

import React from "react";
import { Typography, IconButton, useTheme } from "@mui/material";
import Close from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid2";

interface DismissibleBoxProps {
  emailOrPhone: string;
  onBack: () => void;
  sx?: object;
}

const DismissibleBox: React.FC<DismissibleBoxProps> = (props) => {
  const theme = useTheme();
  const { dismissibleBox } = theme.custom;
  const { emailOrPhone, onBack } = props;
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={dismissibleBox}
    >
      <Typography noWrap flex={1} variant="body3">
        {emailOrPhone}
      </Typography>
      <IconButton onClick={onBack}>
        <Close />
      </IconButton>
    </Grid>
  );
};

export default DismissibleBox;

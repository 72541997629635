import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import ProvideLogoText from "../../components/Icons/ProvideLogo/ProvideLogoText";

const DisclaimerText: React.FC = () => {
  return (
    <Stack width={{ sm: "100%", md: "540px" }} spacing={3}>
      <Typography component="p" variant="body4">
        Provide is trading style of Yellow Stone Finance Group Ltd. Yellow Stone
        Finance is a broker, not a lender and is authorised and regulated by the
        Financial Conduct Authority, registration number 814533.
      </Typography>
      <Typography component="p" variant="body4">
        You should always make sure you are able to afford any repayments as
        late or missed payments can affect your credit rating and access to
        future finance.
      </Typography>
      <Typography component="p" variant="body4">
        YOUR PROPERTY MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON A
        MORTGAGE OR ANY OTHER DEBT SECURED ON IT.
      </Typography>
      <Typography component="p" variant="body4">
        Trading address Unit 1, Verney House, 1b Hollywood Road, London SW10
        9HS. Registered address 303 The Pill Box 115 Coventry Road, London,
        England, E2 6GH.
      </Typography>
      <Typography component="p" variant="body4">
        Yellow Stone Finance Group Ltd - Company Registration number 10734241
        ©2021 by Yellow Stone Finance.
      </Typography>

      <ProvideLogoText width={347} />
      <Box mb={6}>&nbsp;</Box>
    </Stack>
  );
};

export default DisclaimerText;

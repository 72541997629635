import useAxios from "axios-hooks";
import { useEffect } from "react";
import { setUserData } from "../store/slices/settingsSlice";
import { useAppDispatch } from "./useRedux";

const useFetchUser = () => {
  const dispatch = useAppDispatch();
  const [{ data, loading, error }] = useAxios({
    url: "/api/v1/user",
    method: "GET",
  });

  useEffect(() => {
    if (data) {
      dispatch(setUserData(data.data));
    }
  }, [data]);

  return { data, loading, error };
};

export default useFetchUser;

import React from "react";

interface GoogleIconProps {
  width?: number;
  height?: number;
}

const GoogleIcon: React.FC<GoogleIconProps> = (props) => {
  const { width = 26, height = 26 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50308 1.28754C6.82966 2.21497 4.52411 3.97527 2.92507 6.30988C1.32603 8.64448 0.517794 11.4303 0.619075 14.2582C0.720356 17.0861 1.72582 19.807 3.48777 22.0213C5.24972 24.2355 7.6753 25.8264 10.4082 26.5602C12.6238 27.1319 14.9452 27.157 17.1727 26.6333C19.1905 26.1801 21.0561 25.2106 22.5867 23.8197C24.1798 22.3279 25.3361 20.4301 25.9313 18.3304C26.5781 16.0469 26.6933 13.6457 26.2679 11.3109H13.826V16.472H21.0315C20.8875 17.2952 20.5789 18.0808 20.1242 18.782C19.6695 19.4831 19.078 20.0852 18.3851 20.5524C17.5053 21.1347 16.5134 21.5263 15.4732 21.7021C14.43 21.8962 13.3599 21.8962 12.3167 21.7021C11.2593 21.4838 10.259 21.0474 9.37975 20.4207C7.96697 19.4207 6.90616 17.9999 6.3487 16.3612C5.78198 14.6918 5.78198 12.8821 6.3487 11.2126C6.74551 10.0425 7.40149 8.97706 8.26767 8.09589C9.25891 7.06899 10.5138 6.33495 11.8948 5.97432C13.2757 5.61369 14.7294 5.64039 16.0961 6.0515C17.1639 6.37911 18.1403 6.95178 18.9474 7.7238C19.7599 6.91552 20.5709 6.10516 21.3806 5.2927C21.7987 4.85581 22.2544 4.43982 22.6662 3.99248C21.434 2.84595 19.9877 1.95375 18.4102 1.36697C15.5374 0.323854 12.394 0.295821 9.50308 1.28754Z"
        fill="white"
      />
      <path
        d="M9.50373 1.28782C12.3944 0.29543 15.5378 0.322725 18.4108 1.36517C19.9886 1.95593 21.4342 2.85243 22.6647 4.00322C22.2467 4.45056 21.8056 4.86863 21.3792 5.30343C20.5681 6.1131 19.7577 6.91999 18.948 7.72409C18.1409 6.95207 17.1645 6.3794 16.0968 6.05179C14.7305 5.63923 13.2769 5.61098 11.8956 5.97014C10.5143 6.3293 9.25855 7.06198 8.26623 8.08781C7.40005 8.96898 6.74407 10.0344 6.34726 11.2046L2.01392 7.84951C3.56499 4.77365 6.25058 2.42085 9.50373 1.28782Z"
        fill="black"
      />
      <path
        d="M0.863937 11.173C1.09668 10.0186 1.48337 8.90072 2.01364 7.84927L6.34699 11.2127C5.78027 12.8821 5.78027 14.6919 6.34699 16.3613C4.90324 17.4761 3.45879 18.5966 2.01364 19.7226C0.68657 17.081 0.281836 14.0713 0.863937 11.173Z"
        fill="black"
      />
      <path
        d="M13.8261 11.3091H26.268C26.6934 13.6439 26.5783 16.0451 25.9315 18.3286C25.3362 20.4283 24.1799 22.3261 22.5869 23.8179C21.1884 22.7267 19.7837 21.6439 18.3852 20.5527C19.0786 20.0851 19.6703 19.4822 20.1251 18.7804C20.5798 18.0785 20.8882 17.292 21.0316 16.4681H13.8261C13.824 14.7498 13.8261 13.0295 13.8261 11.3091Z"
        fill="black"
      />
      <path
        d="M2.01221 19.7228C3.45735 18.6079 4.9018 17.4875 6.34555 16.3615C6.90412 18.0007 7.96644 19.4216 9.38078 20.421C10.2628 21.0447 11.2652 21.4776 12.324 21.6919C13.3672 21.886 14.4373 21.886 15.4805 21.6919C16.5207 21.5161 17.5126 21.1245 18.3924 20.5422C19.7908 21.6334 21.1956 22.7162 22.594 23.8074C21.0637 25.199 19.1981 26.1693 17.18 26.6231C14.9525 27.1468 12.6312 27.1217 10.4155 26.55C8.66316 26.0821 7.02634 25.2573 5.60765 24.1272C4.10619 22.9349 2.87979 21.4326 2.01221 19.7228Z"
        fill="black"
      />
    </svg>
  );
};

export default GoogleIcon;

import useAxios from "axios-hooks";

const useVerifyEmail = () => {
  const [
    { loading: verifyingEmail, error: verifyError, data: verifyData },
    verifyEmail,
  ] = useAxios(
    {
      url: "/verify-email",
      method: "POST",
    },
    { manual: true },
  );

  const handleVerifyEmail = async (
    id: string,
    hash: string,
    expires: string,
    signature: string,
  ) => {
    try {
      await verifyEmail({
        url: `/verify-email/${id}/${hash}?expires=${expires}&signature=${signature}`,
      });
      return { success: true };
    } catch (error) {
      console.error("Email verification failed:", error);
    }
  };

  return { handleVerifyEmail, verifyingEmail, verifyError, verifyData };
};

export default useVerifyEmail;

import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LoginWave from "../../components/Icons/LoginWave/LoginWave";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import DisclaimerText from "../../components/DisclamerText/DisclamerText";
import DropdownInfoBox from "../../components/DropDownInfoBox/DropDownInfoBox";
import DropShadowBox from "../../components/DropShadowBox/DropShadowBox";
import SignUpBorrower from "../../components/SignUpBorrower/SignUpBorrower";
import {
  checkUrlForSignUpType,
  setUserType,
} from "../../store/slices/signupSlice";
import { useNavigate } from "react-router-dom";
import RootUnauthScreen from "../RootScreen/RootUnauthScreen";

const SignUpScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(checkUrlForSignUpType());
  }, [dispatch]);

  const userType = useAppSelector((state: RootState) => state.signup.userType);

  function handleSwitchUser(): void {
    const option = userType === "borrower" ? "broker" : "borrower";
    const queryString = `${location.pathname}?option=${option}`;
    navigate(queryString);
    dispatch(setUserType(option));
  }

  return (
    <>
      <LoginWave />
      <RootUnauthScreen>
        <Grid container justifyContent="center">
          <Stack
            spacing={3}
            sx={{
              width: {
                xs: "100%",
                md: 603,
                zIndex: 1,
              },
            }}
          >
            <Stack>
              <Typography component="h1" variant="h1">
                Create a Free Account
              </Typography>
              <Typography component="h2" variant="h2">
                Lets gets started. Are you ready to be a part of something new?
              </Typography>
            </Stack>
            <Box>
              <DropShadowBox>
                {userType === "borrower" ? (
                  <SignUpBorrower />
                ) : (
                  <Typography>Broker SignUp</Typography>
                )}
              </DropShadowBox>
            </Box>
            <Grid container spacing={0.75} alignItems="center" wrap="nowrap">
              <Typography component="span" sx={{ ml: 4, color: "gray.dark" }}>
                Want to create a{" "}
              </Typography>
              <Typography
                onClick={handleSwitchUser}
                sx={{
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {userType === "borrower"
                  ? "Broker account instead?"
                  : "Borrower account instead?"}
              </Typography>
            </Grid>
            <DropdownInfoBox />
            <DisclaimerText />
          </Stack>
        </Grid>
      </RootUnauthScreen>
    </>
  );
};

export default SignUpScreen;

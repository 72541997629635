import React, { useEffect, useState, useMemo } from "react";
import {
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Fade,
  useTheme,
} from "@mui/material";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { DrawerMenuItem, DrawerSubItem } from "./data/navigationDrawerData";

interface NavigationDrawerProps {
  open: boolean;
  onClose?: () => void;
  onLeave?: () => void;
  onNavClick: (path: string, index: number | null) => void;
  drawerContent: DrawerMenuItem;
  controlledActiveIndex?: number | null;
}

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  open,
  onLeave,
  drawerContent,
  onNavClick,
  controlledActiveIndex,
}) => {
  const [selectedSubItem, setSelectedSubItem] = useState<number | null>(null);
  const [selectedParentItem, setSelectedParentItem] = useState<number | null>(
    null,
  );
  const [openSubMenuId, setOpenSubMenuId] = useState<number | null>(null);
  const [currentContent, setCurrentContent] =
    useState<DrawerMenuItem>(drawerContent);
  const [isFading, setIsFading] = useState<boolean>(false);
  const [pendingSelection, setPendingSelection] = useState<{
    subItemId: number | null;
    parentItemId: number | null;
  }>({ subItemId: null, parentItemId: null });

  const theme = useTheme();
  const { paper, subListItem, subListItemInnerPadding, headingStyle } =
    theme.custom.navigationDrawer;

  /*
    Reset selected items or apply controlled active index.
    controlledActiveIndex is only called changed when a rail
    item is clicked. If it is - highlight first sub menu item.
    If not, chear all selected items. This is for when you
    select an option without a submenu eg dashboard.
    
  */
  useEffect(() => {
    if (controlledActiveIndex) {
      setSelectedSubItem(controlledActiveIndex);
    } else {
      setSelectedSubItem(null);
      setSelectedParentItem(null);
    }
  }, [controlledActiveIndex]);

  /* 
    We need to chain these effects to ensure that selected menu 
    items are cleared before applying new ones. pendingSelection
    is triggered when you select a submenu option, not a rail option.
  
  */
  useEffect(() => {
    if (pendingSelection.subItemId && pendingSelection.parentItemId) {
      setSelectedSubItem(pendingSelection.subItemId);
      setSelectedParentItem(pendingSelection.parentItemId);
      setPendingSelection({ subItemId: null, parentItemId: null });
    }
  }, [pendingSelection]);

  /* 
    Handle fade-in effect after switching content 
    
  */
  useEffect(() => {
    if (drawerContent !== currentContent) setIsFading(true);
  }, [drawerContent, currentContent]);

  /*
    Fade out then change content

  */
  const handleFadeOutExited = () => {
    setCurrentContent(drawerContent);
    setIsFading(false);
  };

  /* 
    Toggle submenu open state 
    
  */
  const toggleSubMenu = (id: number) =>
    setOpenSubMenuId((prevId) => (prevId === id ? null : id));

  /* 
    Toggle submenu mouse leave state 
    
  */
  const handleLeave = () => {
    setIsFading(false);
    onLeave?.();
  };

  /* 
    Calling onNavClick prop to ensure that correct rail
    item is selected. That in turn will trigger controlledActiveIndex
    which will clear all selected items. Then setPendingSelection
    selects the appropriate icon in the submenu.
    
  */
  const handleSubMenuClick = (parentItemId: number, subItemId: number) => {
    onNavClick(findSubItemPath(subItemId), drawerContent.id);
    setPendingSelection({ subItemId, parentItemId });
  };

  /*
    Get path for the selected submenu item 
    
  */
  const findSubItemPath = (subItemId: number): string => {
    const allItems = Array.isArray(currentContent.items)
      ? currentContent.items.flat()
      : currentContent.items || [];
    return allItems.find((item) => item.id === subItemId)?.path ?? "";
  };

  /* 
    Typography styles based on whether item is a heading 
    
  */
  const getTypographyStyles = (heading: boolean | undefined) => ({
    "& .MuiTypography-root": heading
      ? theme.typography.navHeading
      : theme.typography.navText,
  });

  /*
    Render a dropdown menu list of submenu items 
    
  */
  const renderDropDownMenuItems = (
    subItems: DrawerSubItem[],
    parentId: number,
  ) =>
    subItems.map((subItem) => (
      <ListItemButton
        key={subItem.id || subItem.title}
        selected={selectedSubItem === subItem.id}
        onClick={() => handleSubMenuClick(parentId, subItem.id ?? parentId)}
        sx={{ ...subListItem, ...subListItemInnerPadding }}
      >
        <ListItemText primary={subItem.title} sx={getTypographyStyles(false)} />
      </ListItemButton>
    ));

  /*
    Render a list item button, either as a heading or clickable item 
    
  */
  const renderListItemButtons = (item: DrawerSubItem) => {
    const hasSubItems = !!item.subItems?.length;
    const isSubMenuOpen = openSubMenuId === item.id;

    return item.heading ? (
      <Box sx={headingStyle}>
        <ListItemText primary={item.title} sx={getTypographyStyles(true)} />
      </Box>
    ) : (
      <ListItemButton
        selected={selectedParentItem === item.id || selectedSubItem === item.id}
        onClick={() =>
          hasSubItems
            ? toggleSubMenu(item.id!)
            : handleSubMenuClick(item.id!, item.id!)
        }
        sx={subListItem}
      >
        <ListItemText
          primary={item.title}
          sx={getTypographyStyles(item.heading)}
        />
        {hasSubItems && (isSubMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />)}
      </ListItemButton>
    );
  };

  /*
    Render the main content of the drawer with each menu group 
  
  */
  const renderDrawerMenuItems = (item: DrawerSubItem) => (
    <Box key={item.id || item.title}>
      {renderListItemButtons(item)}
      {item.subItems && (
        <Collapse in={openSubMenuId === item.id} unmountOnExit>
          {renderDropDownMenuItems(item.subItems, item.id!)}
        </Collapse>
      )}
    </Box>
  );

  /*
    Top level what is rendered in return function

  */
  const drawerContentBox = useMemo(
    () => (
      <Fade in={!isFading} timeout={130} onExited={handleFadeOutExited}>
        <List>
          {currentContent.items?.map((group, index) => (
            <Box
              key={`group-${index}`}
              sx={{ mb: Array.isArray(group) ? 3 : 0 }}
            >
              <List component="div" disablePadding>
                {Array.isArray(group)
                  ? group.map(renderDrawerMenuItems)
                  : renderDrawerMenuItems(group)}
              </List>
            </Box>
          ))}
        </List>
      </Fade>
    ),
    [currentContent, isFading, selectedSubItem, openSubMenuId],
  );

  return (
    <Drawer
      anchor="left"
      open={open}
      variant="persistent"
      transitionDuration={400}
      onMouseLeave={handleLeave}
      sx={{
        ...paper,
      }}
    >
      {drawerContentBox}
    </Drawer>
  );
};

export default NavigationDrawer;

import React from "react";
import { IconButton, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useTheme } from "@mui/material/styles";

interface CustomIconButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  ariaLabel?: string;
  sx?: SxProps<Theme>;
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  children,
  onClick,
  ariaLabel,
  sx,
}) => {
  const theme = useTheme();
  const { customIconButton } = theme.custom;
  return (
    <IconButton
      onClick={onClick}
      aria-label={ariaLabel}
      sx={{ ...customIconButton, ...(sx as object) }}
    >
      {children}
    </IconButton>
  );
};

export default CustomIconButton;

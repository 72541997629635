import { SvgIcon } from "@mui/material";
import React from "react";

interface TickCircleProps {
  color: string;
}

export const TickCircle: React.FC<TickCircleProps> = ({ color }) => {
  return (
    <SvgIcon sx={{ fontSize: 30, color: color }} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="10" fill="currentColor" />
      <path d="M9 12l2 2 4-4" fill="none" stroke="black" strokeWidth="2" />
    </SvgIcon>
  );
};

import React from "react";
import { Button, SxProps, Theme } from "@mui/material";
import IconRight from "@mui/icons-material/ArrowForwardIosRounded";

interface ProvideButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  icon?: boolean;
  buttontype?: string;
  variant?: "outlined" | "contained";
  submitbtn?: string;
  sx?: SxProps<Theme>;
}

const ProvideButton: React.FC<ProvideButtonProps> = (props) => {
  const { children, icon, submitbtn, sx, buttontype } = props;
  const buttonStyles = {
    bgcolor: buttontype === "notification" ? "gray.light" : null,
    color: buttontype === "notification" ? "text.disabled" : null,
    ...sx,
  };

  return (
    <Button
      {...props}
      sx={buttonStyles}
      endIcon={icon ? <IconRight /> : null}
      type={submitbtn === "true" ? "submit" : "button"}
    >
      {children}
    </Button>
  );
};

export default ProvideButton;

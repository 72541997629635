import React from "react";
import { Button, Box, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface NavigationButton {
  id: number;
  label: string;
  option: string;
}

interface PillNavigationProps {
  buttons: NavigationButton[];
  pillActiveColor: string;
  reduxSelector: string;
  onClick: (option: string) => void;
}

const PillNavigation: React.FC<PillNavigationProps> = ({
  buttons,
  pillActiveColor,
  reduxSelector,
  onClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    container,
    button,
    activeButton,
    inactiveButton,
    activeButtonStates,
  } = theme.custom.pillNavigation;

  const getButtonStyles = (isActive: boolean): object => {
    const activeStateStyles = isActive ? activeButton : inactiveButton;
    const activeColorStyles =
      isActive && activeButtonStates[pillActiveColor]
        ? activeButtonStates[pillActiveColor]
        : {};

    return {
      ...button,
      ...activeStateStyles,
      ...activeColorStyles,
    };
  };

  const handleClick = (button: NavigationButton) => {
    const queryString = `${location.pathname}?option=${button.option}`;
    navigate(queryString);
    onClick(button.option);
  };

  return (
    <Box sx={container}>
      {buttons.map((button) => {
        const isActive = reduxSelector === button.option;
        const buttonStyles = getButtonStyles(isActive);

        return (
          <Button
            key={button.id}
            sx={buttonStyles}
            onClick={() => handleClick(button)}
          >
            {button.label}
          </Button>
        );
      })}
    </Box>
  );
};

export default PillNavigation;

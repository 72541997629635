import React, { ReactNode, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import { setAuthState } from "../../store/slices/authSlice";
import useFetchUser from "../../hooks/useFetchUser";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface AuthWrapperProps {
  children: ReactNode;
  isProtected: boolean;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children, isProtected }) => {
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading } = useFetchUser();

  // When user data is loaded, set auth state and check for redirects
  useEffect(() => {
    if (!loading) {
      dispatch(setAuthState(!!data));
    }
  }, [data, loading, dispatch]);

  useEffect(() => {
    // Do not redirect if trying to access /verify-password or its subpaths
    if (location.pathname.includes("/verify-email")) return;

    if (isProtected && !isAuthenticated) {
      navigate("/");
    } else if (!isProtected && isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, isProtected, navigate, location.pathname]);

  if (loading) {
    return (
      <Box className="centered">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return <>{children}</>;
};

export default AuthWrapper;

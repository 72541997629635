import React from "react";
import { Typography, SxProps, Theme, useTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Grid from "@mui/material/Grid2";

interface PlainTextButtonProps {
  onClick?: () => void;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  withIcon?: boolean;
}

const PlainTextButton: React.FC<PlainTextButtonProps> = (props) => {
  const theme = useTheme();
  const { plainTextButton } = theme.custom;
  const { onClick, children, sx, withIcon } = props;

  return withIcon ? (
    <Grid container alignItems="center" onClick={onClick} spacing={1}>
      <ChevronLeftIcon />
      <Typography variant="body5" sx={plainTextButton}>
        {children}
      </Typography>
    </Grid>
  ) : (
    <Typography
      variant="body5"
      onClick={onClick}
      sx={{ ...plainTextButton, ...(sx as object) }}
    >
      {children}
    </Typography>
  );
};

export default PlainTextButton;

import React from "react";
import RootAuthScreen from "../RootScreen/RootAuthScreen";

interface DashboardScreenProps {
  infoText: string;
}

const DashboardScreen: React.FC<DashboardScreenProps> = ({ infoText }) => {
  return <RootAuthScreen>{infoText}</RootAuthScreen>;
};

export default DashboardScreen;

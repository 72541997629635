import React, { useState } from "react";
import { Typography, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DropShadowBox from "../DropShadowBox/DropShadowBox";
import ProvideButton from "../Buttons/ProvideButton";

export interface ConfirmPasswordDialogueProps {
  title: string;
  enteredPassword: string | null;
  onConfirm: (newPassword: string, currentPassword: string) => void;
  onClose: () => void;
}

const ConfirmPasswordDialogue: React.FC<ConfirmPasswordDialogueProps> = (
  props,
) => {
  const { title, onConfirm, onClose, enteredPassword } = props;
  const [passwordError, setPasswordError] = useState<string>("");
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    newPassword: enteredPassword || null,
    currentPassword: "",
  });

  const handleFormChange =
    (field: "newPassword" | "currentPassword") =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      setFormData((prev) => ({ ...prev, [field]: value }));
    };

  const handleConfirmClick = () => {
    if (formData.newPassword && formData.currentPassword) {
      setDisableButtons(true);
      setPasswordError("");
      onConfirm(formData.newPassword, formData.currentPassword);
    }
  };

  return (
    <DropShadowBox>
      <Grid maxWidth={340} container alignItems="center" spacing={3}>
        <Typography component="h4" variant="h4">
          {title}
        </Typography>

        <TextField
          label="New Password"
          type="password"
          value={formData.newPassword}
          onChange={handleFormChange("newPassword")}
          fullWidth
        />
        <TextField
          label="Current Password"
          type="password"
          value={formData.currentPassword}
          onChange={handleFormChange("currentPassword")}
          fullWidth
        />
        {passwordError && (
          <Typography textAlign="left" color="error">
            {passwordError}
          </Typography>
        )}
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          columnSpacing={2}
        >
          <Grid>
            <ProvideButton
              disabled={disableButtons}
              onClick={handleConfirmClick}
              variant="contained"
            >
              CONFIRM
            </ProvideButton>
          </Grid>
          <Grid>
            <ProvideButton
              disabled={disableButtons}
              variant="outlined"
              onClick={onClose}
            >
              CLOSE
            </ProvideButton>
          </Grid>
        </Grid>
      </Grid>
    </DropShadowBox>
  );
};

export default ConfirmPasswordDialogue;

import React, { ReactNode } from "react";
import { Modal } from "@mui/material";
import Grid from "@mui/material/Grid2";
interface ModalContainerProps {
  isOpen: boolean;
  children?: ReactNode;
}

const ModalContainer: React.FC<ModalContainerProps> = ({
  isOpen,
  children,
}) => {
  return (
    <Modal open={isOpen}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Grid>{children}</Grid>
      </Grid>
    </Modal>
  );
};

export default ModalContainer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";

interface AuthState {
  userType: string;
}

const initialState: AuthState = {
  userType: "borrower",
};

const signupSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserType: (state, action: PayloadAction<string>) => {
      state.userType = action.payload;
    },
  },
});

export const checkUrlForSignUpType = (): AppThunk => (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search);
  const user = searchParams.get("option");

  if (user === "borrower" || user === "broker") {
    dispatch(setUserType(user));
  }
};

export const { setUserType } = signupSlice.actions;
export default signupSlice.reducer;

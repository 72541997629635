import React from "react";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LoginWave from "../../components/Icons/LoginWave/LoginWave";
import LoginComponent from "../../components/LoginComponent/LoginComponent";
import { Link } from "react-router-dom";
import DisclaimerText from "../../components/DisclamerText/DisclamerText";
import RootUnauthScreen from "../RootScreen/RootUnauthScreen";

const LoginScreen: React.FC = () => {
  return (
    <>
      <LoginWave />
      <RootUnauthScreen>
        <Grid container justifyContent="center">
          <Stack
            spacing={2}
            sx={{
              width: {
                xs: "100%",
                md: 540,
                zIndex: 1,
              },
            }}
          >
            <Typography component="h1" variant="h1">
              Log in to Provide
            </Typography>
            <Grid container justifyContent="center">
              <LoginComponent />
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="left"
              wrap="nowrap"
              direction={{ xs: "column", sm: "row" }}
            >
              <Grid size={{ xs: 12, sm: "auto" }}>
                <Typography component="span" sx={{ color: "gray.dark", ml: 4 }}>
                  Don’t have an account?
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: "auto" }}>
                <Typography
                  component={Link}
                  to="./sign-up"
                  sx={{
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Create a free account now
                </Typography>
              </Grid>
            </Grid>
            <DisclaimerText />
          </Stack>
        </Grid>
      </RootUnauthScreen>
    </>
  );
};

export default LoginScreen;

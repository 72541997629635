import React, { useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import { TickCircle } from "../Icons/TickCircle/TickCircle";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import useFetchTeam from "../../hooks/useFetchTeam";

interface Descendant {
  id: string;
  name: string;
  disabled: number;
}

interface Team {
  id: string;
  name: string;
  disabled: number;
  descendants: Descendant[];
}

const UserSettingsTeams: React.FC = () => {
  const { teams, loading, error } = useFetchTeam();
  const theme = useTheme();
  const { grid } = theme.custom.teamsSettings;

  const TeamHeader = ({
    name,
    expanded,
    onToggle,
    disabled,
  }: {
    name: string;
    expanded: boolean;
    onToggle: () => void;
    disabled: number;
  }) => (
    <Grid container alignItems="center" justifyContent="space-between">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h3" component="h3">
          {name}
        </Typography>
        <IconButton
          onClick={onToggle}
          sx={{
            p: 0,
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.2s",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <TickCircle color={disabled === 0 ? "accent.green" : "accent.cyan"} />
    </Grid>
  );

  const TeamDetails = ({ expanded }: { expanded: boolean }) => (
    <Collapse in={expanded}>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <FormControlLabel sx={{ m: 0, p: 0 }} control={<Checkbox />} label="" />
        <Typography variant="caption">
          (Where enquiries are auto assigned)
        </Typography>
      </Grid>
    </Collapse>
  );

  const DescendantList = ({
    descendants,
  }: {
    descendants: { id: string; name: string; disabled: number }[];
  }) => (
    <Box sx={{ display: "flex", alignItems: "top" }}>
      <SubdirectoryArrowRightIcon sx={{ color: "gray.dark" }} />
      <Stack sx={{ width: "100%" }}>
        {descendants.map((descendant) => (
          <Grid
            key={descendant.id}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography component="h4">{descendant.name}</Typography>
            <TickCircle
              color={descendant.disabled === 0 ? "accent.green" : "accent.cyan"}
            />
          </Grid>
        ))}
      </Stack>
    </Box>
  );

  const TeamItem = ({ item }: { item: Team }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleToggle = () => {
      setExpanded((prev) => !prev);
    };

    return (
      <Grid sx={{ ...grid, maxWidth: 500 }}>
        <TeamHeader
          name={item.name}
          expanded={expanded}
          onToggle={handleToggle}
          disabled={item.disabled}
        />
        <TeamDetails expanded={expanded} />
        {item.descendants.length > 0 && (
          <DescendantList descendants={item.descendants} />
        )}
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box className="centered">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error">{error.response?.data.message}</Typography>
    );
  }

  if (teams?.data?.length) {
    return (
      <>
        <Typography variant="h4" component="h4">
          Yellow Stone Finance
        </Typography>
        {teams.data.map((item: Team) => (
          <TeamItem key={item.id} item={item} />
        ))}
      </>
    );
  }
  return null;
};

export default UserSettingsTeams;
